export const statusList = [
    {
        name: '未处理',
        value: 'todo',
        status: 'error',
    },
    {
        name: '已处理',
        value: 'ongoing',
        status: 'processing'
    },
    {
        name: '已完成',
        value: 'complete',
        status: 'success'
    }
]

export function getStatus(value) {
    const res = statusList.find(item => item.value === value)
    return res ? res.status : ''
}
export function getStatusName(value) {
    const res = statusList.find(item => item.value === value)
    return res ? res.name : ''
}