<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model
            :colon="false"
            :model="form"
            layout="inline"
            @keyup.enter.native="query"
          >
            <a-form-model-item>
              <a-input
                v-model="form.keyword"
                placeholder="标题"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>

            <!-- <a-form-model-item>
              <a-select
                placeholder="阶段"
                style="width: 150px"
                v-model="form.statusList"
                mode="multiple"
              >
                <a-select-option
                  v-for="item in statusList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item> -->

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button
              v-if="$getPermission($route.path + '/add')"
              @click="$router.push($route.path + '/add')"
              type="primary"
              >发布来信</a-button
            >
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-skeleton :loading="loading" :paragraph="{ rows: 8 }">
        <div class="list" v-if="filteredList.length > 0">
          <div
            class="item"
            v-for="item in filteredList"
            :key="item.id"
            @click="openDetail(item)"
          >
            <div class="header">
              <div class="title">{{ item.title }}</div>

              <div class="extra">
                <a-badge
                  v-if="item.status"
                  :status="getStatus(item.status)"
                  :text="getStatusName(item.status)"
                />
              </div>
            </div>

            <div class="content" v-html="item.content"></div>

            <div class="meta">
              <a-space>
                <!-- <a-tooltip>
                  <template slot="title"> 来信人员 </template>
                  <div>
                    <a-icon type="user" class="icon" />
                    <span class="value">{{ item.source }}</span>
                  </div>
                </a-tooltip> -->

                <a-tooltip>
                  <template slot="title"> 来信时间 </template>
                  <div>
                    <a-icon type="clock-circle" class="icon" />
                    <span class="value">{{ item.createAt }}</span>
                  </div>
                </a-tooltip>
              </a-space>

              <div style="margin-left: auto">
                <a-space>
                  <a-tooltip>
                    <template slot="title"> 回复次数 </template>

                    <div>
                      <a-icon type="align-right" class="icon" />

                      <span class="value">
                        {{
                          Array.isArray(item.subList) ? item.subList.length : 0
                        }}
                      </span>
                    </div>
                  </a-tooltip>

                  <a-tooltip>
                    <template slot="title"> 最新回复时间 </template>

                    <div>
                      <a-icon type="clock-circle" class="icon" />
                      <span></span>
                      <span class="value">
                        {{
                          Array.isArray(item.subList) && item.subList.length > 0
                            ? item.subList[0].createAt
                            : "--"
                        }}
                      </span>
                    </div>
                  </a-tooltip>
                </a-space>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="center" style="padding-top: 12px">
          <a-empty />
        </div>
      </a-skeleton>

      <div class="right" style="margin-top: 12px">
        <a-pagination
          :current="current"
          :pageSize="pageSize"
          :total="total"
          @change="onChange"
        />
      </div>
    </a-card>
  </div>
</template>
  
  
<script>
import { statusList, getStatus, getStatusName } from "./data";

import watermark from "@/mixins/watermark";
import request from "@/api/request";

function fetchList(data) {
  return request({
    url: "/common-service/publicLetters/list",
    method: "post",
    data,
  });
}

export default {
  mixins: [watermark],

  data() {
    return {
      form: {},
      statusList,

      loading: false,
      list: [],
      total: 0,

      current: 1,
      pageSize: 10,
    };
  },

  computed: {
    filteredList() {
      return this.list
        .map((item) => {
          const content = item.content.replace(/\n/g, "<br>");
          return {
            ...item,
            content,
          };
        })
        .filter(
          (item, index) =>
            index < this.current * this.pageSize &&
            index >= (this.current - 1) * this.pageSize
        );
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    getStatus,
    getStatusName,

    getList() {
      const { form } = this;
      this.loading = true;

      fetchList({
        ...form,
      })
        .then((res) => {
          if (Array.isArray(res.list) && typeof res.totalSize === "number") {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
            this.current = 1;
            this.pageSize = 10;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    query() {
      this.getList();
    },
    reset() {
      this.form = {};
      this.getList();
    },

    onChange(current) {
      console.log("current", current);
      this.current = current;
    },

    openDetail(text) {
      this.$router.push(this.$route.path + "/detail?id=" + text.id);
    },
  },
};
</script>


<style lang="less" scoped>
.list {
  .item {
    border-bottom: 1px solid #f0f0f0;
    padding: 16px;
    cursor: pointer;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .title {
      font-weight: bold;
      font-size: 16px;
      line-height: 1em;
      color: #1890ff;
    }

    .extra {
      margin-left: auto;
    }
  }

  .content {
    margin-bottom: 12px;
    color: #999;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .meta {
    display: flex;
    align-items: center;
    color: #999;

    .value {
      margin-left: 4px;
    }
  }
}
</style>